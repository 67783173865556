import React, {useState} from "react";
import {useXhr, useLocale, useMessages, useFlashState} from '../util/react-util.js';
import {Info, Success, Error, ErrorInline} from './Messages';
import HelpModal from "./HelpModal";

const base = '/lti';

function propComparator(prop, locale) {
    return function(a, b) {
        if (a[prop].localeCompare) {
            return a[prop].localeCompare(b[prop], locale, {numeric: true});
        } else {
            return a[prop] < b[prop] ? -1 : 1;
        }
    };
}

export function SchemaGroupInput(props) {
    var m = useMessages();
    return (
        <label key={props.group.externalId}>
            <input
                type='checkbox'
                name={props.group.externalId}
                checked={props.group.schema}
                onChange={props.onChange}
            />
            &nbsp; {props.group.groupName} &nbsp;
            {props.group.missingInCanvas && <ErrorInline>{m('groupMissingInCanvas')}</ErrorInline>}
        </label>);
}

export function SchemaSectionInput(props) {
    var m = useMessages();
    return (
        <label key={props.section.externalId}>
            <input
                type='checkbox'
                name={props.section.externalId}
                checked={props.section.schema}
                onChange={props.onChange}
            />
            &nbsp; {props.section.sectionName} &nbsp;
            {props.section.missingInCanvas && <ErrorInline>{m('sectionMissingInCanvas')}</ErrorInline>}
        </label>);
}


function getGroupsInCategory(allGroups, categoryId) {
    return allGroups.filter(g => g.groupCategoryId === categoryId);
}

function areAllSelected(choices) {
    return (choices||[]).reduce(function(acc, g) {
        return acc && g.schema;
    }, true);
}

export function SelectAll(props) {
    var choices = props.choices,
        [selected, setSelected] = useState(areAllSelected(choices)),
        m = useMessages();
    return (
        <label>
            <input
                type='checkbox'
                checked={selected}
                onChange={function(e) {
                    var updatedGroups = choices.map(function(g) {
                        return Object.assign({}, g, {schema: !selected });
                    });
                    props.onChange(updatedGroups);
                    setSelected(!selected);
                }} />
            {m('selectAll')}
        </label>);
}

export function SelectAllInGroupCategory(props) {
    var groupsInCategory = props.groupsInCategory,
        m = useMessages(),
        allSelected = props.allInCategoriesSelected[props.category];
        if (allSelected === undefined){
            allSelected = false;
        }
    return (
        <label>
            <input
                type='checkbox'
                checked={allSelected}
                onChange={function(e) {
                    var updatedGroups = props.allGroups.map(function(g) {
                        return (g.groupCategoryId !== props.category) ? g : Object.assign({}, g, {
                            schema: !allSelected});
                    });
                    props.onChange(updatedGroups);
                    var allSelectedObject = {};
                    var key = props.category;
                    allSelectedObject[key] = !allSelected;
                    props.setAllInCategoriesSelected(Object.assign({}, props.allInCategoriesSelected, allSelectedObject));
                }} />
            {m('selectAll')}
        </label>);
}

export function SchemaSectionsInput(props) {
    var m = useMessages(),
        locale = useLocale(),
        sections = props.sections.sort(propComparator('sectionName', locale));
    if (!props.sections) return null;
    return (
        <div>
        <div className="row no-print">
            <div className="col-md-12">
                <h3 className="toggle-header mt-4">
                    <button className="toggle-button button-text button-icon m-0 collapsed" data-bs-toggle="collapse"
                            data-bs-target="#toggle-sections" aria-controls="toggle-sections" aria-expanded="false">{m('scheduleSections')}</button>
                </h3>
                <div id="toggle-sections" className="toggle-content collapse">
                    <div className="card">
                        <div className="card-body">
                            <p>{m('scheduledSectionsInfo')}</p>
                            <div className="card">
                                <div className="card-body">
                                    {sections.length > 1 && <div>
                                    <SelectAll
                                        choices={sections}
                                        onChange={props.onChange}/>
                                    <br/><br/>
                                    </div>}
                                    <div className="list-columns">
                                        <ul className="list-unstyled">
                                    {sections.map(function(s) {
                                    return (
                                        <div key={s.externalId}>
                                            <li key={s.externalId} className='nobullet'>
                                            <SchemaSectionInput
                                                section={s}
                                                onChange={function(e) {
                                                    var updatedSections = sections.map(function(s2) {
                                                        return (s2.externalId !== s.externalId) ? s2 : Object.assign({}, s, {
                                                            schema: !s.schema
                                                        });
                                                    });
                                                    props.onChange(updatedSections);
                                                }}/>
                                            </li>
                                        </div>)
                                    })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>);
}

export function SchemaGroupsInput(props) {
    var m = useMessages(),
        locale = useLocale(),
        groups = props.groups.sort(propComparator('groupName', locale)),
        groupCategories = props.groupCategories.sort(propComparator('name', locale)),
        [allInCategoriesSelected, setAllInCategoriesSelected] = useState({});

    if (!props.groups) return null;

    return (
        <div>
        <div className="row no-print">
        <div className="col-md-12">
            <h3 className="toggle-header mt-4">
                <button className="toggle-button button-text button-icon m-0 collapsed" data-bs-toggle="collapse"
                        data-bs-target="#toggled-groups" aria-controls="toggled-groups" aria-expanded="false">{m('scheduleGroups')}</button>
            </h3>
            <div id="toggled-groups" className="toggle-content collapse">
            <div className="card">
            <div className="card-body">
                <p>{m('scheduledGroupsInfo')}</p>
                <h4>{m('groupCategories')}</h4>
                <div className="filter-content card">
                <div className="card-body">
                <div className="row">
                <div className="col-md-12">
                <div>
                <div className="toggle-filter">
                {groupCategories.map((category, index) =>
                    <div key={category.id} className="border-top">
                        <button aria-expanded="false" className="toggle-button button-text button-icon collapsed"
                                data-bs-toggle="collapse" data-bs-target={'#category' + category.id}>
                            {category.name}
                        </button>
                        <div className="col-md-12">
                        <div className="row collapse" id={'category' + category.id}>
                        <div className="ms-4">
                        <fieldset>
                            <div className="mb-3">
                                {getGroupsInCategory(groups, category.id).length > 1 &&
                                    <div>
                                        <SelectAllInGroupCategory
                                            category={category.id}
                                            allGroups={groups}
                                            groupsInCategory={getGroupsInCategory(groups, category.id)}
                                            allInCategoriesSelected={allInCategoriesSelected}
                                            setAllInCategoriesSelected={setAllInCategoriesSelected}
                                            onChange={props.onChange}/>
                                        <br/><br/>
                                    </div>}
                            </div>
                            <div className="list-columns">
                            <ul className="list-unstyled">
                                {getGroupsInCategory(groups, category.id).map((group, index) =>
                                    <div key={index}>
                                        <li key={index} className='nobullet'>
                                            <SchemaGroupInput
                                                group={group}
                                                onChange={function(e) {
                                                    // updatedGroups are all groups but some of them with boolean schema potentially changed
                                                    var updatedGroups = groups.map(function(gr) {
                                                        return (gr.externalId !== group.externalId) ? gr : Object.assign({}, group, {
                                                            schema: !group.schema
                                                        });
                                                    });
                                                    props.onChange(updatedGroups);
                                                }}/>
                                        </li>
                                    </div>
                                )}
                            </ul>
                            </div>
                        </fieldset>
                        </div>
                        </div>
                        </div>
                    </div>
                )}
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        </div>
        </div>);
}

export function SchemaContextInput(props) {
    var m = useMessages();
    return (
        <div>
            <p>{m('schemaContextText')}</p>
            <p>
                <label>
                    <input
                        type='checkbox'
                        checked={props.schema}
                        onChange={props.onChange} />
                    {m('schemaContextLabel')}
                </label>
            </p>
        </div>);
}

function isContextSchemaOptional(schemaContext) {
    return schemaContext?.externalId?.match(/^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/) ? false : true;
}

export default function SchemaContextAndGroupsInput(props) {
    var [context, setContext] = props.schemaContext,
        contextRepo = useXhr(),
        [flashSuccess, setFlashSuccess] = useFlashState(),
        optional = isContextSchemaOptional(context),
        schema = context.schema,
        groups = context?.schemaGroups?.length > 0,
        sections = context?.schemaSections?.length > 0,
        m = useMessages();
    if (!context.accountId) return <div><strong>{m('noAccountId')}</strong></div>;
    if (!context.term) return <div><strong>{m('noTerm')}</strong></div>
    return (
    <div>
        <h2>{m('schemaContextHeader')}</h2>
        {!optional && <p>{m('schemaContextCourseIsScheduledInTimeEdit')}</p>}
        <div>
            {optional &&
             <SchemaContextInput
                 schema={schema}
                 onChange={function() {
                     setContext(Object.assign({}, context, {
                         schema: !schema
                     }));
                 }} />}
            {schema &&
                <div className="row no-print">
                    <div className="col-md-12">
                        <div className="float-end mb-2">
                            <button className="no-print button-text button-icon icon-help" data-bs-toggle="modal" data-bs-target="#helpModal">{m('show.help')}</button>
                        </div>
                        <HelpModal><p>HelpModal</p></HelpModal>
                    </div>
                </div>
            }
            {schema && sections &&
                <SchemaSectionsInput
                    sections={context.schemaSections}
                    onChange={function(sections) {
                        setContext(Object.assign({}, context, {
                            schemaSections: sections
                        }));
                    }}/>}
            {schema && groups &&
             <SchemaGroupsInput
                 groups={context.schemaGroups}
                 groupCategories={context.groupCategories}
                 onChange={function(groups) {
                     setContext(Object.assign({}, context, {
                         schemaGroups: groups
                     }));
                 }}/>}
            <br/>
            {(optional || groups) &&
             <button onClick={ function() {
                         contextRepo.post(base + '/schemaContext', context, function(rs) {
                             setContext(rs);
                             setFlashSuccess(rs);
                             props.onSave();
                         });
                     }}>{m('save')}
             </button>}
            {contextRepo.waiting && <Info>{m('saving')}</Info>}
            {contextRepo.error && <Error>{contextRepo.error}</Error>}
            {flashSuccess && <Success>{m('saved')}</Success>}
        </div>
    </div>);
}
